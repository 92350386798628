
import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { UsuarioService } from '@/core/services/cadastros';
import { Permissao, Usuario } from '@/core/models/cadastros';

@Component
export default class PermissaoAdm extends mixins(Vue,ListPage) {
  item = new Usuario();
  service = new UsuarioService();
  permissao = Permissao;
    
  titulo: string = 'Permissão do Sistema SCAE';
  subTitulo: string = 'Atenção, as permissões serão concedida ao usuário.';
  tabActive = null;
  usuarios = [];

  dialogDespesa:boolean = false;
  dialogReceita:boolean = false;
  dialogEmpresa:boolean = false;
  dialogUsuario:boolean = false;
  dialogCompras:boolean = false;
  dialogCentroCusto:boolean = false;
  dialogContaGerencial:boolean = false;
  dialogMovimentacao:boolean = false;
  dialogContrato:boolean = false;
  dialogPedido:boolean =false;
  dialogMedicao:boolean = false;

  filterId:number = 1;

  modulos = [
    {id:1, nome:'Almoxarifado'},
    {id:2, nome:'Base'},
    {id:3, nome:'Contrato'},
    {id:4, nome:'Compras'}, 
    {id:5, nome:'Empreendimento'},
    {id:6, nome:'Financeiro'},
    {id:7, nome:'Geral'},
    {id:8, nome:'Projeto'},
    {id:9, nome:'Relatório'},
    {id:10, nome:'Módulo'}
  ]

  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Permissão', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'permissaoa', value: 'permissao' },
    { text: 'Visualizar', value: 'visualizar' },
    { text: 'Cadastrar', value: 'cadastrar' } ,
    { text: 'alterar', value: 'alterar' }  ,
    { text: 'excluir', value: 'excluir' } 
  ];


  getUsuario(){
    this.service.getUsuario(this.item.id).then(res => {
      this.item = res.data;

      if(this.item.permissoes == null){
        this.item.permissoes = [];
      }
    })
  }

  permissoesAlmoxarifado:any = [1004,1001,1002,1003,2004,2001,2002,2003, 3001,3002,3003,3004,4004,4001,4002,4003,4005,5001,5002,5003,5004,6001,6002,6003,6004,7001,8001,9001,9002,9003,9004];

  permissoesBase:any = [10001,10002,10003,10004];

  permissoesContrato:any = [11001,11002,11003,11004,11005,11006,11007,12001,12002,12003,12004,13001,14001];

  permissoesCompras:any = [15001,15002,15003,15004, 15005,15006, 16001,16002,16003,16004, 17001,17002,17003,17004, 17005,18001,19001 ];

  permissoesEmpreendimento:any = [20001,20002,20003,20004, 21001,22001, 23001,24001,25001,26001, 26002,26003,26004];

  permissoesRelatorio:any = [38001];

  permissoesProjeto:any = [61001,61002,61003,61004,62001,62002,62003,62004, 63001,63002,63003,63004,63005,64001,64002,64003,64004,65001];

  permissoesGerais:any = [46001,46002,46003,46004,47001,47002,47003,47004, 47005,47006,47007,47008,48001,49001,50001,50002,51001,52001,52002,52003,52004,52005,53001,54001,54002,54003,
  54004,55001,56001,57001,58001,59001,59002,59003,59004,60001,60002,60003,60004,60005,60006,60007];

  permissoesFinanceiro:any = [27001,27002,27003,27004,28001,28002,28003,28004,28005,29001,29002,29003,29004,30001,30002,30003,30004,31001,31002,31003,31004,31005,32001,32002,32003,32004,
  32005,32006,32007,32008,32009,32010,32011,32012,32013,33001,33002,33003,33004,33005,33006,33007,33008,33009,33010,33011,33012,33013,33014,33015,33016,33017,33018,33019,33020,33021,33022,33023
  ,33024,33025,34001,34002,34003,34004,35001,35002,35003,35004,37001,37002,37003,37004,38001,39001,40001,36001,41001,43001,44001,45001,42001,42002,42003,42004,33027,67001,67002,67003,67004,68004,68001,68002,68003,33028,33029,33030,33031, 32014,33032];

  permissoesModulos:any = [999001, 999002, 999003,999004, 999005, 999006, 999007, 999008,999009];


  /* VERIFICAÇÃO INCLUDES */
  get includeAlmoxarifado(){
    return this.item.permissoes.some((el) => this.permissoesAlmoxarifado.includes(el))
  };

  get includeBase(){
    return this.item.permissoes.some((el) => this.permissoesBase.includes(el))
  };
  
  get includeContrato(){
    return this.item.permissoes.some((el) => this.permissoesContrato.includes(el))
  }

  get includeCompras(){
    return this.item.permissoes.some((el) => this.permissoesCompras.includes(el))
  }

  get includeEmpreendimento(){
    return this.item.permissoes.some((el) => this.permissoesEmpreendimento.includes(el))
  }

  get includeRelatorio(){
    return this.item.permissoes.some((el) => this.permissoesRelatorio.includes(el))
  }

  get includeProjeto(){
    return this.item.permissoes.some((el) => this.permissoesProjeto.includes(el))
  }

  get includeGeral(){
    return this.item.permissoes.some((el) => this.permissoesGerais.includes(el))
  }

  get includeFinanceiro(){
    return this.item.permissoes.some((el) => this.permissoesFinanceiro.includes(el))
  }

  get includeModulo(){
    return this.item.permissoes.some((el) => this.permissoesModulos.includes(el))
  }

  /* ICONES */

  get iconAlmoxarifado(){
    if (this.includeAlmoxarifado) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconBase(){
    if (this.includeBase) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconContrato(){
    if (this.includeContrato) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconCompras(){
    if (this.includeCompras) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconEmpreendimento(){
    if (this.includeEmpreendimento) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconRelatorio(){
    if (this.includeRelatorio) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconProjeto(){
    if (this.includeProjeto) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconGeral(){
    if (this.includeGeral) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }

  get iconFinanceiro(){
    if (this.includeFinanceiro) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }
  get iconModulo(){
    if (this.includeModulo) return 'mdi-close-box'
    return 'mdi-checkbox-blank-outline'
  }
  
  SelectAllAlmoxarifado(){
    this.$nextTick(() => {  
      if (this.includeAlmoxarifado) {
         this.item.permissoes = this.item.permissoes.filter(x => x != 1001 && x != 1002 && x != 1003 && x != 1004 && x != 2004 && x != 2001 && x != 2002 && x != 2003 && x != 3004 && x != 3001 && x != 3002 && x != 3003
         && x != 4004 && x != 4001 && x != 4002 && x != 4003 && x != 4005 && x != 5004 && x != 5001 && x != 5002 && x != 5003 && x != 6004 && x != 6001 && x != 6002 && x != 6003 && x != 7001 && x != 8001  && x != 9001 && x != 9002 && x != 9003 && x != 9004);
      } else {
        this.permissoesAlmoxarifado.forEach(element =>{
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllBase(){
     this.$nextTick(() => {  
      if (this.includeBase) {
        this.item.permissoes = this.item.permissoes.filter(x => x != 10001 && x != 10002 && x != 10003 && x != 10004);
      } else {

        this.permissoesBase.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }
 
  SelectAllContrato(){
    this.$nextTick(() => {  
      if (this.includeContrato) {
        this.item.permissoes = this.item.permissoes.filter(x => x != 11001 && x != 11002 && x != 11003 && x != 11004 && x != 11005 && x != 11006 && x != 11007 && x != 12001 && x != 12002 && x != 12003 && x != 12004 && x != 13001 && x != 14001);
      } else {

        this.permissoesContrato.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllCompras(){
     this.$nextTick(() => {  
      if (this.includeCompras) {
        this.item.permissoes = this.item.permissoes.filter(x => x != 15001 && x != 15002 && x != 15003 && x != 15004 && x != 15005 && x != 15006
         && x != 16001 && x != 16002 && x != 16003 && x != 16004 && x != 17001 && x != 17002 && x != 17003 && x != 17004  && x != 17005 && x != 18001 && x != 19001);
      } else {

        this.permissoesCompras.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllEmpreendimento(){
    this.$nextTick(() => {  
      if (this.includeEmpreendimento) {
        this.item.permissoes = this.item.permissoes.filter(x => x != 20001 && x != 20002 && x != 20003 && x != 20004 && x != 21001 && x != 22001
         && x != 23001 && x != 24001 && x != 25001 && x != 26001 && x != 26002 && x != 26003 && x != 26004);
      } else {

        this.permissoesEmpreendimento.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllRelatorio(){
    this.$nextTick(() => {  
      if (this.includeRelatorio) {
        this.item.permissoes = this.item.permissoes.filter(x => x != 38001);
      } else {

        this.permissoesRelatorio.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllProjeto(){
    this.$nextTick(() => {  
      if (this.includeProjeto) {
        this.item.permissoes = this.item.permissoes.filter(x => x != 61001 && x != 61002 && x != 61003 && x != 61004 && x != 62001 && x != 62002 && x != 62003 && x != 62004 && x != 63001
        && x != 63002 && x != 63003 && x != 63004 && x != 63005 && x != 64001 && x != 64002 && x != 64003 && x != 64004 && x != 65001);
      } else {

        this.permissoesProjeto.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllGeral(){
    this.$nextTick(() => {  
      if (this.includeGeral) {
        this.item.permissoes = this.item.permissoes.filter(x => x  != 46001 && x != 46002 && x != 46003 && x != 46004 && x != 47001 && x != 47002 && x != 47003 && x != 47004
         && x != 47005  && x != 47006  && x != 47007  && x != 47008  && x != 48001 && x != 49001 && x != 50001 && x != 50002 && x != 51001 && x != 52001 && x != 52002 && x != 52003 && x != 52004
          && x != 52005  && x != 53001  && x != 54001  && x != 54002  && x != 54003  && x != 54004 && x != 55001 && x != 56001 && x != 57001 && x != 58001 && x != 59001 && x != 59002 && x != 59003 && x != 59004
          && x != 60001 && x != 60002 && x != 60003 && x != 60004 && x != 60005 && x != 60006 && x != 60007);
      } else {

        this.permissoesGerais.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllFinanceiro(){
     this.$nextTick(() => {  
      if (this.includeFinanceiro) {
        this.item.permissoes = this.item.permissoes.filter(x => x  != 27001 && x != 27002 && x != 27003 && x != 27004 && x != 28001 && x != 28002 && x != 28003 && x != 28004 && x != 28005 
          && x != 29001 && x != 29002 && x != 29003 && x != 29004 && x != 30001 && x != 30002 && x != 30003 && x != 30004 && x != 31001 && x != 31002 && x != 31003 && x != 31004 && x != 31005
          && x != 32001 && x != 32002 && x != 32003  && x != 32004 && x != 32005 && x != 32006 && x != 32007 && x != 32008 && x != 32009 && x != 32010 && x != 32011 && x != 32012 && x != 32013
          && x != 33001 && x != 33002 && x != 33003 && x != 33004 && x != 33005 && x != 33006 && x != 33007 && x != 33008 && x != 33009 && x != 33010 && x != 33011 && x != 33012
          && x != 33013  && x != 33014  && x != 33015  && x != 33016  && x != 33017  && x != 33018  && x != 33019  && x != 33020  && x != 33021  && x != 33022  && x != 33023  && x != 33024
          && x != 33025  && x != 34001  && x != 34002  && x != 34003  && x != 34004  && x != 35001  && x != 35002  && x != 35003  && x != 35004  && x != 37001  && x != 37002 && x != 37003 
          && x != 37004  && x != 38001  && x != 39001  && x != 40001  && x != 36001  && x != 41001  && x != 43001  && x != 44001  && x != 45001  && x != 42001  && x != 42002  && x != 42003 && x != 42004 && x != 33027 
          && x != 67001 && x != 67002 && x != 67003 && x != 67004 && x != 68004 && x != 68001 && x != 68002 && x != 68003 && x != 33028 && x != 33029 && x != 33030 && x != 33031 && x != 33032 && x != 32014
                                                                                                                                                                                   
        );
      } else {
        this.permissoesFinanceiro.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }

  SelectAllModulo(){
    this.$nextTick(() => {  
      if (this.includeModulo) {
        this.item.permissoes = this.item.permissoes.filter(x => x != 999001 && x != 999002 && x != 999003 && x != 999004 && x != 999005 && x != 999006 && x != 999007 && x != 999008 && x != 999009);
      } else {

        this.permissoesModulos.forEach(element => {
          this.item.permissoes.push(element)
        })
      }
    })
  }
 
  Salvar() {
    this.service.PostPermissoes(this.item.id, this.item.permissoes).then(
    (res) => {
      this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
      this.$emit("salvou");
    }, 
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    });
  }

  mounted(){
    new UsuarioService().Listar(-1, -1, ['login'],[],'',[], '', '' , 'Id, Login', '').then(
      res => this.usuarios = res.data.items
    )
  }
}
 
